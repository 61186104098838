<template>
  <div class="container" :class="{ copy: showLogo }" id="login">
    <div class="head">
      <img
        v-if="!showLogo"
        @click="enterIndex"
        src="../assets/indexImg/logo1.png"
      />
      <div class="btn_group">
        <el-button type="primary" @click="isLogin(0)">
          {{ $t("i18n.signIn") }}
        </el-button>
        <el-button class="el-button--transparent" @click="isLogin(1)">
          {{ $t("i18n.signUp") }}
        </el-button>
      </div>
    </div>
    <div class="register-form" v-show="isLoginData === 1">
      {{ $t("i18n.underConstruction") }}
    </div>
    <div class="forget-form" v-show="isLoginData === 2">
      <h2>{{ $t("i18n.resetPwd") }}</h2>
      <el-form
        :model="forgetForm"
        :rules="rules"
        ref="forgetFormRef"
        label-width="70px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('i18n.phoneNo') + '：'" prop="phoneNo">
          <el-input
            :disabled="nextStep"
            v-model="forgetForm.phoneNo"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="!nextStep"
          class="code"
          label="验证码："
          prop="authCode"
        >
          <el-input v-model="forgetForm.authCode"></el-input>
          <el-button
            :disabled="forgetDisabled"
            type="primary"
            @click="sendMessage"
          >
            {{ btnTitle }}
          </el-button>
        </el-form-item>
        <el-form-item v-if="!nextStep">
          <el-button type="primary" @click="submitForm(1)">
            {{ $t("i18n.nextStep") }}
          </el-button>
        </el-form-item>
        <el-form-item
          v-if="nextStep"
          key="passwordPlain"
          :label="$t('i18n.newPassword')"
          prop="passwordPlain"
        >
          <el-input
            type="password"
            v-model="forgetForm.passwordPlain"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="nextStep"
          key="confirmPassword"
          :label="$t('i18n.confirmPassword')"
          prop="confirmTokenPlain"
        >
          <el-input
            type="password"
            v-model="forgetForm.confirmTokenPlain"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="nextStep">
          <el-button type="primary" @click="submitForm(2)">
            {{ $t("i18n.confirm") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="login-form" v-show="isLoginData === 0">
      <el-form :model="form" :rules="rules" ref="formRef" class="demo-ruleForm">
        <h3>登录LOG IN</h3>
        <p><span v-if="!showLogo">巨立云</span>智能物联网系统</p>
        <el-form-item prop="username">
          <el-input
            id="username"
            v-model="form.username"
            @keyup.enter="login"
            :placeHolder="$t('i18n.input')"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            id="password"
            v-model="form.password"
            @keyup.enter="login"
            :placeHolder="$t('i18n.input')"
          ></el-input>
        </el-form-item>
        <el-form-item class="text-right">
          <a href="javascript:void(0);" @click="isLogin(2)">
            {{ $t("i18n.forgotPwd") }}
          </a>
        </el-form-item>
        <el-form-item>
          <el-button
            style="font-size: 20px"
            type="primary"
            @click="login"
            v-loading="disabled"
          >
            {{ $t("i18n.signIn") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, computed, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import store from "../utils/store";
export default {
  name: "Login",
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const forgetFormRef = ref(null);
    const formRef = ref(null);
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.forgetForm.confirmTokenPlain !== "") {
          forgetFormRef.value.validateField("confirmTokenPlain");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.forgetForm.passwordPlain) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    emit("public_header", false);
    const state = reactive({
      showLogo: localStorage.getItem("from") === "juli",
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: t("i18n.userNameEm"), trigger: "blur" },
        ],
        password: [
          { required: true, message: t("i18n.passwordEm"), trigger: "blur" },
        ],
        phoneNo: [
          { required: true, message: t("i18n.input"), trigger: "blur" },
        ],
        authCode: [
          { required: true, message: t("i18n.input"), trigger: "blur" },
        ],
        passwordPlain: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        confirmTokenPlain: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      disabled: false, // 防止重复点击
      isLoginData: computed(() => parseInt(route.query.flag)), // 判断显示1注册2忘记密码else登录
      queryArr: [],
      flag: true,
      forgetForm: {
        authCode: "",
        phoneNo: "",
        passwordPlain: "",
        confirmTokenPlain: "",
      },
      forgetDisabled: false,
      btnTitle: "获取验证码",
      nextStep: false,
      check_token: "",
    });

    const login = () => {
      if (!state.disabled) {
        formRef.value.validate((valid) => {
          if (valid) {
            state.disabled = true;
            state.form.username = state.form.username.trim();
            state.form.password = state.form.password.trim();
            proxy.$api.common.loginApi(state.form).then((res) => {
              state.disabled = false;
              var token = res.data.access_token;
              var userId = res.data.user_id;
              localStorage.setItem("token", token);
              localStorage.setItem("userId", userId);
              localStorage.setItem("username",state.form.username);
              proxy.$defined.setToken(token);
              store.commit("auth_success", token, res.data);
              getMenu();
            });
          } else {
            return false;
          }
          setTimeout(() => {
            state.disabled = false;
          }, 10000);
        });
      } else {
        proxy.$defined.tip("正在登录中，请勿重复点击");
      }
    };

    const getMenu = async () => {
      const res = await proxy.$api.common.menuApi();
      state.menus = res.data;
      if (res.data.length == 0) {
        proxy.$defined.tip("该用户没有菜单，请添加菜单权限后使用系统！");
        proxy.$api.common.logoutApi({ userId: localStorage.getItem("userId") });
        return;
      }
      localStorage.setItem(
        "menu",
        window.encodeURIComponent(JSON.stringify(res.data))
      );
      proxy.$defined.setMenu(res.data);
      state.queryArr = [];
      parseJson(state.menus);
      var arr = [];
      state.queryArr.map((item) => {
        arr.push(item.name);
      });
      localStorage.setItem("query", window.encodeURIComponent(arr));
      proxy.$defined.setQuery(arr);
      // setTimeout(()=>{
      emit("public_header", true);
      // },500)
    };

    const parseJson = (jsonObj) => {
      for (var key in jsonObj) {
        var element = jsonObj[key];
        if (element.children.length > 0) {
          parseJson(element.children);
        } else {
          if (state.queryArr.length == 0 && state.flag) {
            state.flag = false;
            router.replace({
              path: element.href,
            });
          }
          if (element.query) {
            state.queryArr = state.queryArr.concat(element.query);
          }
        }
      }
    };

    const enterIndex = () => {
      router.push({
        path: "/",
        query: { from: localStorage.getItem("from") },
      });
    };

    const isLogin = (flag) => {
      router.push({
        path: "/login",
        query: {
          flag: flag,
        },
      });
    };

    const submitForm = (flag) => {
      forgetFormRef.value.validate((valid) => {
        if (valid) {
          flag == 1 ? validateCode() : setNewPassFunc();
        } else {
          return false;
        }
      });
    };

    const setNewPassFunc = async () => {
      let token = state.check_token;
      let obj = {
        confirmTokenPlain: state.forgetForm.confirmTokenPlain,
        passwordPlain: state.forgetForm.passwordPlain,
      };
      await proxy.$api.common.setNewPass(token, obj);
      proxy.$defined.tip(t("i18n.resetSuccess"), "success");
      isLogin(0);
    };

    const validateCode = async () => {
      const res = await proxy.$api.common.checkTelCode(
        state.forgetForm.phoneNo,
        state.forgetForm.authCode
      );
      state.nextStep = true;
      state.check_token = res.description; // TK69edc74d86b245d4bad1bbd8aa32c6b2
    };

    const sendMessage = async () => {
      if (state.forgetForm.phoneNo == "") {
        proxy.$defined.tip(t("i18n.phoneNotEmpty"));
        return;
      }
      await proxy.$api.common.getTelCode(state.forgetForm.phoneNo);
      validateBtn();
    };

    const validateBtn = () => {
      //倒计时
      let time = 60;
      proxy.$defined.tip(t("i18n.sentMessageSuccessfully"), "success");
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          state.forgetDisabled = false;
          state.btnTitle = "获取验证码";
        } else {
          state.btnTitle = time + "秒后重试";
          state.forgetDisabled = true;
          time--;
        }
      }, 1000);
    };

    return {
      ...toRefs(state),
      login,
      getMenu,
      parseJson,
      enterIndex,
      isLogin,
      submitForm,
      setNewPassFunc,
      validateCode,
      sendMessage,
      validateBtn,
      forgetFormRef,
      formRef,
    };
  },
};
</script>

<style lang="scss">
#login {
  max-width: 100%;
  min-height: 100%;
  margin: 0 !important;
  background: url("../assets/indexImg/loginBackground.png") no-repeat center
    center;
  background-size: cover;
  .head {
    max-width: $width * 12+35;
    margin: 0 auto;
    .btn_group {
      float: right;
      padding-top: 6px;

      button {
        width: 79px;
        height: 31px;
        line-height: 31px;
      }
    }
  }

  .login-form {
    background: #fff url("../assets/indexImg/denglukuangbj.png") no-repeat
      center center;
    .el-form {
      width: 40%;
      float: right;
      padding: 64px 79px 0 0;

      h3 {
        font-weight: normal;
        font-size: 2 * $font + 10;
      }

      p {
        font-size: $font + 5;
        margin: 10px 0 30px;
      }

      input {
        padding-left: 30px;
        border: none;
        height: 55px;
        font-size: 16px;
        border-radius: 0;
        border-bottom: 1px solid $border_color;
      }

      #username {
        background: url("../assets/indexImg/yonghuming.png") no-repeat 0 center;
      }

      #password {
        background: url("../assets/indexImg/mima.png") no-repeat 0 center;
      }

      button {
        width: 250px;
        margin-left: 50px;
        height: 53px;
        border: none;
        outline: none;
        background: url(../assets/indexImg/denglvanniubj.png) no-repeat center
          center;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .register-form,
  .login-form,
  .forget-form {
    width: 9 * $width + 36;
    height: 5 * $width + 46;
    border-radius: 30px;
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .register-form {
    text-align: center;
    line-height: 5 * $width + 46;
    font-size: $font * 3;
  }

  .forget-form {
    padding: 40px 60px;

    h2 {
      text-align: center;
      font-size: 28px;
      margin-bottom: 30px;
    }

    .el-form-item {
      margin-top: 40px;
    }

    .el-button {
      border-radius: 4px;
      width: 100%;
      height: 40px;
    }

    .code {
      .el-input {
        width: 83%;
      }

      .el-button {
        width: 15%;
        margin: 0 0 0 2%;
      }
    }
  }
}
#login.copy {
  background: url("../assets/indexImg/loginBackgroundCopy.png") no-repeat center
    center;
  background-size: cover;
}
@media screen and (max-width: 1024px) {
  #login {
    min-height: 100%;
    .register-form,
    .login-form,
    .forget-form {
      width: 80%;
      height: 440px;
      top: 120px;
      transform: translate(-50%, 0);
    }
    .login-form {
      .el-form {
        padding: 35px 40px;
        button {
          margin-left: 0;
        }
      }
    }
    .forget-form {
      .code {
        .el-input {
          width: 82%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #login {
    padding: 15px 10px;
    .head {
      .btn_group {
        button {
          min-width: 30px;
        }
      }
    }
    .register-form,
    .login-form,
    .forget-form {
      width: 90%;
      height: 70%;
      min-height: 450px;
      background: #fff;
      border-radius: 10px;
      top: 80px;
      transform: translate(-50%, 0);
    }
    .login-form {
      .el-form {
        width: 100%;
        padding: 30px 20px;
        float: none;
        h3,
        p {
          text-align: center;
        }
        button {
          margin-left: 25px;
        }
      }
    }
    .register-form {
      font-size: 30px;
      line-height: 400px;
    }
    .forget-form {
      padding: 30px 20px;
      .code {
        .el-input {
          width: 60%;
        }
        .el-button {
          min-width: 80px;
          padding: 0 !important;
        }
      }
      .el-form-item {
        &:last-child {
          .el-form-item__content {
            margin: 0 !important;
            text-align: center;
            button {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
</style>
